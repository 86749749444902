import { domReady } from 'utilities/dom';
import { declareJavaScriptInitialized, setCookie } from 'utilities/browser';
import { setPageLevelDataCapture, Reporter, visibilityObserver } from 'features/dataCapture';
import { attachRateUsHandler } from 'features/rateUs/desktop';
import { trackMdemCookie, utagLinkFooter, utagLinkChat } from 'features/tealium';
import { setGenericEvent } from 'features/tealium/ga4/custom';
import getRecommendedItems from 'features/certona/desktop';
import { openMessenger } from 'features/genesysCloud';

import dynamicImport from '../../../features/webpack/dynamicImport.macro';
import initNoCookiesOverlay from '../../noCookiesOverlay';

import initAssetCounts from './assetCounts';
import initEmailSubscribe from './emailSubscribe';
import initFreeShippingCallout from './freeShippingCallout';
import initHeaderNavigation from './headerNavigation';
import initSearch from './search';
import initSignInDropDown from './signInDropDown';
import initStoreLocations from './storeLocations';
import initUnveil from './unveil';
import initStopWorkingWithSpecialist from './stopWorkingWithSpecialist';

if (window.resx) {

	window.resx.prodDetailCertonaSkusUsed = [];

}

window.lp.certona.getRecommendedItems = getRecommendedItems;

function initStoreNumber () {

	dynamicImport('desktop/layouts/page-storeNumber', './storeNumber/index.js').then((module) => module.default());

}

function initializeDataCapture () {

	if (!window.lp.globals.enableDataCapture || !window.lp.globals.canShowCertona) {

		return;

	}

	const { reporter } = window.lp.dataCapture;

	const elementObserver = visibilityObserver((element) => {

		const data = Reporter.getSkuViewData(element);
		reporter.track(data);

	});

	document.addEventListener('recommendedItems:loaded', () => {

		const elements = document.querySelectorAll('#recentlyViewedContainer img[data-sku]');
		elements.forEach((element) => elementObserver.observe(element));

	});

}

function initHeaderTracking () {

	const liveChatLink = document.querySelector('#liveHeaderChatLink');

	if (liveChatLink) {

		utagLinkChat('Live-Chat')({
			eventAction: 'Load-Chat',
			eventLabel: 'Top-Nav',
			nonInteraction: 1
		});

		const chatButton = liveChatLink.querySelector('.chatButtonContainer button');
		chatButton?.addEventListener('click', (el) => {

			const buttonText = el.currentTarget.innerText || '';

			openMessenger();
			const eventLabel = el.currentTarget.dataset.gaLabel
				? el.currentTarget.dataset.gaLabel
				: el.currentTarget.parentNode.dataset.gaLabel;

			utagLinkChat('Live-Chat')({
				eventAction: 'Request-Chat',
				eventLabel,
				nonInteraction: 1
			});

			setGenericEvent({
				event_name: 'navtop_chat_click'
			});

			setGenericEvent({
				event_name: 'chat_click',
				chat_button_type: 'static',
				chat_button_text: buttonText
			});

		});

	}

	dynamicImport('desktop/layouts/page-headerTracking', './headerTracking/index.js').then((module) => module.default());

}

function initFooterTracking () {

	const trackFooterEvent = utagLinkFooter();
	const emailUs = document.getElementById('ftrEmailUs');
	const backToTop = document.querySelector('.backToTop');
	const emailSubscribeInput = document.querySelector('#txtEmailUpdatesRequest');
	const emailSubscribeBtn = document.querySelector('#ftrSubscribeBtn');
	const proLogo = document.getElementById('ftrProsLogo');
	const hospitalityLogo = document.getElementById('ftrHospitalityLogo');
	// get all column links (exclude phone email and text links)
	const columnLinks = [
		...document.querySelectorAll(
			'#footer [data-footer-column] a, #footer_rate_us, #ftrLegal div a.ftrBottomLinks, .ftrSocialMedia .hashTagLampsplus'
		)
	].filter((link) => !/^mailto:|tel:|sms:/gi.test(link.href));
	const socialLinks = document.querySelectorAll('#footer a[data-social-text]');
	const liveChat = document.getElementById('ftrChat');

	function handleColumnLinkClick (e) {

		if (e.currentTarget.id === 'ftrEmailUs') {

			return;

		}

		const linkText = e.currentTarget.textContent.trim();
		trackFooterEvent({ eventAction: `Click-${linkText}` });

	}

	function handleSocialLinkClick (e) {

		const { socialText } = e.currentTarget.dataset;
		if (socialText) {

			trackFooterEvent({ eventAction: `Click-${socialText}` });

		}

	}

	const trackFooterChatEvents = (chatButton, eventLabel) => {

		chatButton?.addEventListener('click', (el) => {

			const buttonText = el.currentTarget.innerText || '';

			openMessenger();
			trackFooterEvent({
				eventCategory: 'Live-Chat',
				eventAction: 'Request-Chat',
				eventLabel,
				nonInteraction: 0
			});

			setGenericEvent({
				event_name: 'chat_click',
				chat_button_type: 'static',
				chat_button_text: buttonText
			});

		});

	};

	if (liveChat) {

		trackFooterEvent({
			eventCategory: 'Live-Chat',
			eventAction: 'Load-Chat',
			eventLabel: 'Global-Footer',
			nonInteraction: 1
		});

		const chatButton = liveChat.querySelector('.chatWrapperWithIcon button');

		trackFooterChatEvents(chatButton, 'Global-Footer');

	}
	const prosChatButton = document.querySelector('#ftrLinksSection .proContactOption .chatWrapperWithIcon button');

	if (prosChatButton) {

		trackFooterChatEvents(prosChatButton, 'Pros-Global-Footer');

	}

	if (emailUs) {

		emailUs.addEventListener('click', () => trackFooterEvent({ eventAction: 'Click-Email-Us' }), { once: true });

	}
	if (backToTop) {

		backToTop.addEventListener('click', () => trackFooterEvent({ eventAction: 'Click-Back-to-Top' }));

	}

	if (emailSubscribeBtn && emailSubscribeInput) {

		emailSubscribeBtn.addEventListener('click', () => {

			// fire different event actions depending on whether user provided text value in email field
			trackFooterEvent({
				eventAction:
					emailSubscribeInput.value !== '' ? 'Click-Subscribe-Email' : 'Click-Subscribe-Email-Not-Submitted'
			});

		});

	}

	if (proLogo) {

		proLogo.addEventListener('click', () => trackFooterEvent({ eventAction: 'Click-LPPro' }), { once: true });

	}

	if (hospitalityLogo) {

		hospitalityLogo.addEventListener('click', () => trackFooterEvent({ eventAction: 'Click-Hospitality' }), {
			once: true
		});

	}

	columnLinks.forEach((link) => link.addEventListener('click', handleColumnLinkClick));
	socialLinks.forEach((link) => link.addEventListener('click', handleSocialLinkClick));

}

function initFooterTrackingEvents () {

	const columnLinks = document.querySelectorAll('[data-footer-click]');
	const emailSubscribeBtn = document.querySelector('#ftrSubscribeBtn');
	const emailUs = document.getElementById('ftrEmailUs');
	const liveChat = document.getElementById('ftrChat');
	const socialLinks = document.querySelectorAll('#footer a[data-social-icon]');

	function handleColumnLinkClick (e) {

		const { footerClick } = e.currentTarget.dataset;

		if (!footerClick) {

			return;

		}

		setGenericEvent({
			event_name: `footer_${footerClick}_click`
		});

	}

	function handleSocialLinkClick (e) {

		const { socialIcon } = e.currentTarget.dataset;
		if (socialIcon) {

			setGenericEvent({ event_name: `footer_${socialIcon}_click` });

		}

	}

	const trackFooterChatEvents = (chatButton) => {

		chatButton?.addEventListener('click', () => {

			setGenericEvent({
				event_name: 'footer_Request-Chat_click'
			});

		});

	};

	if (liveChat) {

		setGenericEvent({
			event_name: 'footer_Load-Chat_click'
		});

		setGenericEvent({
			event_name: 'chat_is_live',
			page_type: ''
		});

		const chatButton = liveChat.querySelector('.chatWrapperWithIcon button');

		trackFooterChatEvents(chatButton);

	} else {

		const genesysFloating = document.createElement('div');
		genesysFloating.innerHTML = '<style> #messenger, #genesys-messenger { display: none; }</style>';
		document.body.appendChild(genesysFloating);

	}

	if (emailSubscribeBtn) {

		emailSubscribeBtn.addEventListener('click', () => {

			setGenericEvent({
				event_name: 'footer_email_subscribe'
			});

		});

	}

	if (emailUs) {

		emailUs.addEventListener('click', () => setGenericEvent({ event_name: 'footer_Email_click' }), {
			once: true
		});

	}

	columnLinks.forEach((link) => link.addEventListener('click', handleColumnLinkClick));
	socialLinks.forEach((link) => link.addEventListener('click', handleSocialLinkClick));

}

function initPromoBanner() {

	const closeButton = document.querySelector('.promoBanner__close');

	closeButton?.addEventListener('click', () => {

		const promoBanner = document.querySelector('.promoBanner');

		promoBanner.style.display = 'none';
		setCookie(
			'PromoBannerClosed-10-10-2024',
			'true',
			0,
			window.lp.globals.cookieDomain,
			undefined,
			true,
			true
		);

	});
	
}

if (window.lp.globals.enableDataCapture) {

	setPageLevelDataCapture();
	window.lp.dataCapture.reporter = new Reporter();

}

domReady(() => {

	initAssetCounts();
	initEmailSubscribe();
	initFreeShippingCallout();
	initHeaderNavigation();

	initSearch();
	initSignInDropDown();
	initStoreLocations();
	initUnveil();
	initStopWorkingWithSpecialist();
	attachRateUsHandler(document.querySelector('#footer_rate_us'));

	if (window.lp.globals.isCustomerService && !window.lp.globals.isKioskWithoutStoreModifier) {

		initStoreNumber();

	}

	initHeaderTracking();
	initializeDataCapture();
	initFooterTracking();
	initFooterTrackingEvents();

	/*
		TODO: If we need hi-res, each init could return a promise,
		and when all are resolved, declare global JS initialized
	*/
	// For Test Automation
	declareJavaScriptInitialized('global');

	trackMdemCookie();
	if (window.lp.globals.isHospitality) {

		$('.sIHouzz').removeClass('hidden');

	} else {

		$('.sIHouzz').addClass('hidden');

	}

	initNoCookiesOverlay();
	initPromoBanner();

});
